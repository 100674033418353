import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';

type Props = {
  className?: string;
};

const FlipkartImage = ({ className }: Props) => {
  const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "flipkart.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  if (!data?.placeholderImage?.childImageSharp?.fluid) {
    return <div>Picture not found</div>;
  }

  return (
    <>
      <Img
        className={className}
        fluid={data.placeholderImage.childImageSharp.fluid}
      />
    </>
  );
};

export default FlipkartImage;
